import { graphql, StaticQuery, PageProps } from "gatsby";
import { Column, Row } from "hedron";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import SEO from "../components/seo";
import { IJurassicOutpostSettings } from "../types/settings";
import SharingBox from "../components/SharingBox";
import SideBar from "../components/SideBar";
import styled from "../lib/styled";
import useAxios from "../lib/use-axios";
import { IWordpressNodeRest } from ".";
import ArticleMeta from "../components/ArticleMeta";
import qs from "qs";

const he = require("he");
interface ISettings {
  jurassicOutpostSettings: Pick<
    IJurassicOutpostSettings,
    "notFoundTitle" | "notFoundContent"
  >;
}

interface IData {
  allWp: {
    nodes: ISettings[];
  };
}

interface IProps {
  data: IData;
  location: PageProps["location"];
}

interface FeaturedMediaResponse {
  media_details: {
    sizes: {
      full: {
        source_url: string;
      };
    };
  };
}

const NotFoundPage = ({ data, location }: IProps) => {
  const [pageData, setPageData] = React.useState<IWordpressNodeRest>();
  const [featuredMedia, setFeaturedMedia] = React.useState<
    FeaturedMediaResponse
  >();
  const wpRestEndpoint =
    "https://controlroom.jurassicoutpost.com/wp-json/wp/v2";

  const [response, fetchPost] = useAxios<IWordpressNodeRest>((slug) => ({
    url: `${wpRestEndpoint}/posts/${slug}?_embed`,
    method: "GET",
  }));

  const [featuredMediaData, fetchFeaturedMedia] = useAxios<
    FeaturedMediaResponse
  >((id) => ({
    url: `${wpRestEndpoint}/media/${id}`,
    method: "GET",
  }));

  React.useEffect(() => {
    if (response.data && response.data) {
      setPageData(response.data);
    }
  }, [response]);

  React.useEffect(() => {
    if (featuredMediaData.data) {
      setFeaturedMedia(featuredMediaData.data);
    }
  }, [featuredMediaData]);

  React.useEffect(() => {
    const { id, _thumbnail_id } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (id) {
      fetchPost(id);
    }
    if (_thumbnail_id) {
      fetchFeaturedMedia(_thumbnail_id);
    }
  }, [location]);

  return (
    <>
      {response.pending ? (
        <p>Loading!</p>
      ) : pageData ? (
        <Layout location={location}>
          <Row>
            <SEO
              title={ReactHtmlParser(pageData.title.rendered)}
              author={
                pageData._embedded.author && pageData._embedded.author[0].name
              }
            />
            <Column
              md={9}
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: "0px",
              }}
            >
              {featuredMedia && featuredMedia.media_details && (
                <FeaturedImage
                  src={featuredMedia.media_details.sizes.full.source_url}
                />
              )}
              <PageContent>
                <>
                  <ContentHeader>
                    {ReactHtmlParser(pageData.title.rendered)}
                  </ContentHeader>
                  {pageData._embedded.author && (
                    <ArticleMeta
                      author={{
                        name: pageData._embedded.author[0].name,
                        url: pageData._embedded.author[0].slug,
                        customAvatar: null,
                      }}
                      date={pageData.date}
                      title={pageData.title.rendered}
                      slug={pageData.slug}
                      id={pageData.id}
                    />
                  )}
                  {ReactHtmlParser(pageData.content.rendered)}
                </>
              </PageContent>
            </Column>
            <Column md={3}>
              <SideBar
                Sharing={
                  <SharingBox
                    title={pageData.title.rendered}
                    slug={pageData.slug}
                    excerpt={pageData.excerpt.rendered}
                  />
                }
              />
            </Column>
          </Row>
        </Layout>
      ) : (
        <Layout location={location}>
          <Row>
            <SEO title="404: Not found" description="Page not found" />
            <Column md={12}>
              <PageContent>
                <>
                  <h1>
                    {ReactHtmlParser(
                      data.allWp.nodes[0].jurassicOutpostSettings.notFoundTitle
                    )}
                  </h1>
                  {ReactHtmlParser(
                    he.decode(
                      data.allWp.nodes[0].jurassicOutpostSettings
                        .notFoundContent
                    )
                  )}
                </>
              </PageContent>
            </Column>
          </Row>
        </Layout>
      )}
    </>
  );
};
export default (props) => (
  <StaticQuery
    query={graphql`
      query notFoundPageQuery {
        allWp {
          nodes {
            jurassicOutpostSettings {
              notFoundTitle
              notFoundContent
            }
          }
        }
      }
    `}
    render={(data) => <NotFoundPage data={data} {...props} />}
  />
);

const ContentHeader = styled.h1`
  font-family: "Bebas Neue";
`;

const FeaturedImage = styled.img`
  margin-bottom: 0;
  width: 100%;
`;
